import React , {useEffect,useState} from "react"

import { connect } from "react-redux";


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Container, Row,Col, Card, CardBody, Label, Form, Alert, Input, FormFeedback } from 'reactstrap';
import { Link , useNavigate , useParams} from 'react-router-dom';

import axios from "axios";
import { base_url } from "../../Constants";
const Edit = (props) => {
  document.title = "All Branches";
  const breadcrumbItems = [
    { title: "SuperOffice", link: "#" },
    { title: "Dashboard", link: "/dashboard" },
    { title: "Branches", link: "/all Branches" }
  ];
  const { id } = useParams();
  const [item,setItem] = useState({});
  const [formData, setFormData] = useState({
    name: item.name
  });
  const [message,setMessage] = useState('');
  const [showLoader,setShowLoader] = useState(false);
  const navigate = useNavigate(); 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  useEffect(() => {
        props.setBreadcrumbItems('Dashboard' , breadcrumbItems); 
        const  user =  JSON.parse(localStorage.getItem('authUser'));
        let config = {
            headers: {
              'Authorization': 'Bearer ' + user.token
            }
          }
        axios.get(base_url+'branches/single/'+id,config).then(response=>{
            setItem(response.data.data);
            setFormData({
                name:response.data.data.name
            })
        })
  },[])
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    try {
        const  user =  JSON.parse(localStorage.getItem('authUser'));
        let config = {
            headers: {
              'Authorization': 'Bearer ' + user.token
            }
          }
        axios.post(base_url+'branches/edit/'+id,formData,config).then(response=>{
            const res =  response.data;
            if (res.state == 1) {
                setMessage(res.msg);
                setShowLoader(false);
                navigate('/branches');
            }else{
                setShowLoader(false);
                setMessage(res.msg); 
            }
        })
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };
 

  return (
    <React.Fragment>
        
        <Card className="overflow-hidden">
            <CardBody className="pt-0">

                

                <div className="p-3">
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={handleSubmit}
                    > 
                        {message && <h3>{message}</h3>}
                        {showLoader && (
                          <div id="preloader" style={{ position: 'absolute' }}>
                            <div id="status">
                              <div className="spinner-chase">
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="mb-3">
                            <Label htmlFor="username">Name</Label>
                            <Input
                              name="name"
                              className="form-control"
                              placeholder="Enter name"
                              type="text"
                              value={formData.name} onChange={handleChange} 
                            />
                            
                        </div>
                        
                        <Row className="mb-3 mt-4">
                            
                            <div className="col-6 text-end">
                                <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Submit</button>
                            </div>
                        </Row>
                    </Form>
                </div>
            </CardBody>
        </Card>
                        
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Edit);