// Index.js
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import axios from "axios";
import { base_url } from "../../Constants";
import { Link } from "react-router-dom";
import { Button , Alert, Card ,CardBody } from 'reactstrap';
import MyModal from "./MyModal";
import AddExpensesType from "../Modals/AddExpensesType"
import Edit from "./Edit";
const Index = (props) => {
  document.title = "All Properties";
  const breadcrumbItems = [
    { title: "SuperOffice", link: "#" },
    { title: "Dashboard", link: "/" },
    { title: "Expenses Types", link: "/expenses-types" }
  ];

  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [flashMessage, setFlashMessage] = useState("");
  const [showLoader,setShowLoader] = useState(true);
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const toggleModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(!isModalOpen);
  };
  const user = JSON.parse(localStorage.getItem('authUser'));
    let config = {
        headers: {
        'Authorization': 'Bearer ' + user.token
        }
    };
  const handleDelete = () => {
    setIsModalOpen(false); 
    setShowLoader(true);
    axios.get(base_url+'expenses-types/delete/'+selectedItem.id, config).then(response => {
        if (response.data.state == 1) {
            setItems(response.data.data);
            
            setFlashMessage("Property deleted successfully!");
            setShowFlashMessage(true);
            setShowLoader(false);
            setTimeout(() => setShowFlashMessage(false), 3000); 
         }
       
      });
    
  };
  const addItem = (newItem) => {
    setItems([...items, newItem]);
  };
  useEffect(() => {
    props.setBreadcrumbItems('Dashboard', breadcrumbItems);
    const user = JSON.parse(localStorage.getItem('authUser'));
    let config = {
      headers: {
        'Authorization': 'Bearer ' + user.token
      }
    };
    axios.get(base_url+'expenses-types', config).then(response => {
      const res = response.data;
      setItems(res.data);
      setShowLoader(false);
    });
  }, []);

  return (
    <React.Fragment>
      
       <Card>
           <CardBody>
            {showLoader &&(
                <div id="preloader" style={{position:'absolute'}}>
                  <div id="status">
                    <div className="spinner-chase">
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                    </div>
                  </div>
                </div>
            )}
           
              {showFlashMessage && (
                <Alert color="success">
                  {flashMessage}
                </Alert>
              )}
              <AddExpensesType addItem={addItem} />
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Options</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map(item => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>
                        <Edit item={item}  setItems={setItems}/>
                        <Button color="danger" onClick={() => toggleModal(item)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <MyModal
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(!isModalOpen)}
                title="Delete Property"
                onDelete={handleDelete}
              >
                <p>Are you sure you want to delete this property?</p>
              </MyModal>
           </CardBody>
       </Card>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(Index);
