import React , {useEffect,useState} from "react"

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Container, Row,Col, Card, CardBody, Label, Form, Alert, Input, FormFeedback } from 'reactstrap';
import { Link } from 'react-router-dom';

import axios from "axios";
import { base_url } from "../../Constants";
const Add = (props) => {
  document.title = "All Branches";
  const breadcrumbItems = [
    { title: "SuperOffice", link: "#" },
    { title: "Dashboard", link: "/dashboard" },
    { title: "Branches", link: "/all Branches" }
  ];
  const [formData, setFormData] = useState({
    name: '',
  });
  const [message,setMessage] = useState('');
  const navigate = useNavigate(); 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  useEffect(() => {
        props.setBreadcrumbItems('Dashboard' , breadcrumbItems); 
  },[])
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const  user =  JSON.parse(localStorage.getItem('authUser'));
        let config = {
            headers: {
              'Authorization': 'Bearer ' + user.token
            }
          }
        axios.post(base_url+'branches/add',formData,config).then(response=>{
            const res =  response.data;
            if (res.state == 1) {
                setMessage(res.msg);
                navigate('/branches');
            }else{
                setMessage(res.msg); 
            }
        })
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };
 

  return (
    <React.Fragment>
            
        <Card className="overflow-hidden">
            <CardBody className="pt-0">

                

                <div className="p-3">
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={handleSubmit}
                    > 
                    {message && <h3>{message}</h3>}
                        <div className="mb-3">
                            <Label htmlFor="username">Name</Label>
                            <Input
                              name="name"
                              className="form-control"
                              placeholder="Enter name"
                              type="text"
                              value={formData.name}  
                            />
                            
                        </div>
                        
                        <Row className="mb-3 mt-4">
                            
                            <div className="col-6 text-end">
                                <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Submit</button>
                            </div>
                        </Row>
                    </Form>
                </div>
            </CardBody>
        </Card>
                        
                   
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Add);