import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input, Row } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const AddExpenses = ({ addItem }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [branches, setBranches] = useState([]);
  const [properties, setProperties] = useState([]);
  const [clients, setClients] = useState([]);
  const [formData, setFormData] = useState({
    amount: 1,
    name: null,
    branch_id: null,
    properity_id: null,
    client_id: null,
    file: null,
  });

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'file' ? files[0] : value,
    });
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
    if (name === 'branch_id') {
      handleBranchChange(selectedOption.value);
    }
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  const config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };

  const handleBranchChange = async (branchId) => {
    try {
      const response = await axios.get(`${base_url}expenses/needs?branch_id=${branchId}`, config);
      const propertiesData = response.data.properities.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setProperties(propertiesData);
      if (propertiesData.length > 0) {
        setFormData((prevData) => ({ ...prevData, properity_id: propertiesData[0].value }));
      }
    } catch (error) {
      console.error('Error fetching properties', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    try {
      const formPayload = new FormData();
      Object.keys(formData).forEach((key) => {
        formPayload.append(key, formData[key]);
      });

      const response = await axios.post(`${base_url}payments/add`, formPayload, config);
      const res = response.data;
      if (res.state === 1) {
        setMessage(res.msg);
        const newItem = {
          id: res.data.id,
          no: res.data.no,
          amount: res.data.amount,
          name: res.data.name,
          branch: res.data.branch,
          client: res.data.client,
          properity: res.data.properity,
          date: res.data.date,
        };
        addItem(newItem);
        toggle();
      } else {
        setMessage(res.msg);
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${base_url}payments/needs`, config);
        const branchesData = response.data.branches.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setBranches(branchesData);
        if (branchesData.length > 0) {
          setFormData((prevData) => ({ ...prevData, branch_id: branchesData[0].value }));
        }
        const propertiesData = response.data.properities.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setProperties(propertiesData);
        if (propertiesData.length > 0) {
          setFormData((prevData) => ({ ...prevData, properity_id: propertiesData[0].value }));
        }
        const clientsData = response.data.clients.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setClients(clientsData);
        if (clientsData.length > 0) {
          setFormData((prevData) => ({ ...prevData, client_id: clientsData[0].value }));
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Button className="pullRight" color="primary" onClick={toggle}>
        +
      </Button>
      <Modal isOpen={modal} toggle={toggle} style={{ minWidth: '900px',background:'#000' }}>
        <ModalHeader toggle={toggle}>Add Payment</ModalHeader>
        <ModalBody>
          {message && <h3>{message}</h3>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
            <Row>
                <div className='col-md-12'>
                  <FormGroup>
                    <Label for="branch">Name</Label>
                  <Input id="name" type='text' min="1" name="name"
                       value={formData.name} onChange={handleChange} />
                  </FormGroup>
                </div>
                <div className='col-md-4'>
                  <FormGroup>
                    <Label for="branch">Branch</Label>
                    <Select
                      name="branch_id"
                      options={branches}
                      value={branches.find(branch => branch.value === formData.branch_id)}
                      onChange={handleSelectChange}
                    />
                  </FormGroup>
                </div>
                <div className='col-md-4'>
                  <FormGroup>
                    <Label for="property">Property</Label>
                    <Select
                      name="properity_id"
                      options={properties}
                      value={properties.find(property => property.value === formData.properity_id)}
                      onChange={handleSelectChange}
                    />
                  </FormGroup>
                </div>
                <div className='col-md-4'>
                  <FormGroup>
                    <Label for="property">Client</Label>
                    <Select
                      name="client_id"
                      options={clients}
                      value={clients.find(client => client.value === formData.client_id)}
                      onChange={handleSelectChange}
                    />
                  </FormGroup>
                </div>
            </Row>
            <Row>
              <div className='col-md-6'>
                <FormGroup>
                  <Label for="name">Ampunt</Label>
                  <Input id="amount" type='number' min="1" name="amount" value={formData.amount} onChange={handleChange} />
                </FormGroup>
              </div>
              <div className='col-md-6'>
                  <FormGroup>
                    <Label for="file">File</Label>
                    <Input type="file" id="file" name="file" accept=".pdf,.png,.jpeg,.jpg,.xlsx" onChange={handleChange} />
                  </FormGroup>
              </div>
            </Row>
            <Button type="submit">Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddExpenses;
