import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input , Alert } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const ImportExecel = ({ setItems , setCurrentPage }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [branches, setBranches] = useState([]);
  const [properties, setProperties] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [formData, setFormData] = useState({
    file: null,
  });

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'file' ? files[0] : value,
    });
  };

 

  const user = JSON.parse(localStorage.getItem('authUser'));
  const config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    try {
      const formPayload = new FormData();
      Object.keys(formData).forEach((key) => {
        formPayload.append(key, formData[key]);
      });

      const response = await axios.post(`${base_url}leads/import`, formPayload, config);
      const res = response.data;
      if (res.state === 1) {
        setMessage('File Imported Successfully');
        setItems(res.data); 
        setCurrentPage(1);
        setShowLoader(false);
        setTimeout(() => {
          toggle();
          setMessage('')
        }, 1000);
        
      } else {
        setMessage(res.msg);
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    } finally {
      setShowLoader(false);
    }
  };

  

  return (
    <span>
      <Button className="pullRight" style={{marginLeft:'10px'}} color="success" onClick={toggle}>
        Import
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Import</ModalHeader>
        <ModalBody>
           {message && <Alert color="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
            <FormGroup>
              <Label for="file">File</Label>
              <Input type="file" id="file" name="file" accept=".xlsx" onChange={handleChange} />
            </FormGroup>
            <Button type="submit">Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </span>
  );
};

export default ImportExecel;
