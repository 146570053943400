import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import Miniwidget from "./Miniwidget";
import MonthlyEarnings from "./montly-earnings";
import EmailSent from "./email-sent";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";
import { setBreadcrumbItems } from "../../store/actions";
import axios from "axios";
import { base_url } from "Constants";

const Dashboard = (props) => {
  document.title = "Dashboard | SuperOffice";
  const [reports, setReports] = useState([
    { title: "Leads", link:'/leads', iconClass: "cube-outline", total: "0", average: "+11%", badgecolor: "info" },
    { title: "Rents ",link:'/rents' ,iconClass: "tag-text-outline", total: "0", average: "0%", badgecolor: "warning" },
    { title: "Forms",link:'/customer-forms' , iconClass: "briefcase-check", total: "0", average: "+89%", badgecolor: "info" },
  ]);
  const [account, setAccount] = useState({
    payments: 0,
    expenses: 0,
    rents: 0,
  });
  const [items, setItems] = useState(null);
  const [clients, setClients] = useState(null);
  const [leads_data, setLeadsData] = useState(null);

  useEffect(() => {
    const breadcrumbItems = [
      { title: "SuperOffice", link: "#" },
      { title: "Dashboard", link: "#" }
    ];
    props.setBreadcrumbItems('Dashboard', breadcrumbItems);

    const user = JSON.parse(localStorage.getItem('authUser'));
    const config = {
      headers: { 'Authorization': 'Bearer ' + user.token }
    };

    axios.get(`${base_url}statistics`, config).then(response => {
      const res = response.data;
      setReports(prevReports =>
        prevReports.map(report => {
          if (report.title === "Leads") {
            return { ...report, total: res.leads };
          }  else if (report.title === "Rents") {
            return { ...report, total: res.rents };
          } else if (report.title === 'Forms') {
            return { ...report, total: res.forms };
          } else {
            return report;
          }
        })
      );
    });
  }, []);
  return (
    <React.Fragment>
      <Miniwidget reports={reports} />
    </React.Fragment>
  );
};
export default connect(null, { setBreadcrumbItems })(Dashboard);
