import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import IndexHome from "./index";
import UserPage from "./UserPage";
import Renewal from "./Renewal";
const Dashboard = (props) => {
  document.title = "Dashboard | SuperOffice";
  
  const  user =  JSON.parse(localStorage.getItem('authUser'));
  return (
    <React.Fragment>
      {user.type == 0?<IndexHome />:user.type == 2 ?<Renewal />:<UserPage />}
    </React.Fragment>
  );
};
export default connect(null, { setBreadcrumbItems })(Dashboard);
