import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const EditDocument = ({ item,updateItem }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [branches, setBranches] = useState([]);
  const [properties, setProperties] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [formData, setFormData] = useState({
    name: item.name,
    branch_id: { value: item.branch_id, label: item.branch },
    properity_id: { value: item.properity_id, label: item.properity },
    file: null,
  });

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'file' ? files[0] : value,
    });
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
    if (name === 'branch_id') {
      handleBranchChange(selectedOption.value);
    }
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  const config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };

  const handleBranchChange = async (branchId) => {
    try {
      const response = await axios.get(`${base_url}expenses/needs?branch_id=${branchId}`, config);
      const propertiesData = response.data.properities.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setProperties(propertiesData);
      if (propertiesData.length > 0) {
        setFormData((prevData) => ({ ...prevData, properity_id: propertiesData[0].value }));
      }
    } catch (error) {
      console.error('Error fetching properties', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    try {
      const formPayload = new FormData();
      Object.keys(formData).forEach((key) => {
        console.log(key);
        formPayload.append(key, formData[key]);
      });

      const response = await axios.post(`${base_url}documents/edit/${item.id}`, formPayload, config);
      const res = response.data;
      if (res.state === 1) {
        setMessage(res.msg);
        const newItem = {
          id: res.data.id,
          name: res.data.name,
          branch: res.data.branch,
          properity: res.data.properity,
        };
        updateItem({
          ...item,
          name: formData.name,
          branch_id: res.data.branch_id,
          branch: res.data.branch,
          properity_id: res.data.properity_id,
          properity: res.data.properity,
          file:res.data.file
        });
        toggle();
      } else {
        setMessage(res.msg);
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${base_url}expenses/needs`, config);
        const branchesData = response.data.branches.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setBranches(branchesData);
        if (branchesData.length > 0) {
          setFormData((prevData) => ({ ...prevData, branch_id: branchesData[0].value }));
        }
        const propertiesData = response.data.properities.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setProperties(propertiesData);
        if (propertiesData.length > 0) {
          setFormData((prevData) => ({ ...prevData, properity_id: propertiesData[0].value }));
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, []);

  return (
    <element>
      <Button style={{marginRight:'5px'}} className="pullRight" color="primary" onClick={toggle}>
        Edit 
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Documnet "{item.name}" ee</ModalHeader>
        <ModalBody>
          {message && <h3>{message}</h3>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}

            <FormGroup>
              <Label for="name">Name</Label>
              <Input id="name" name="name" value={formData.name} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="file">File</Label>
              <Input type="file" id="file" name="file" accept=".pdf,.png,.jpeg,.jpg,.xlsx" 
              onChange={handleChange} />
            </FormGroup>
            <iframe style={{width:'100%'}} src={item.file}></iframe>
            <FormGroup>
              <Label for="branch">Branch</Label>
              <Select
                name="branch_id"
                options={branches}
                value={branches.find(branch => branch.value === formData.branch_id)}
                onChange={handleSelectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="property">Property</Label>
              <Select
                name="properity_id"
                options={properties}
                value={properties.find(property => property.value === formData.properity_id)}
                onChange={handleSelectChange}
              />
            </FormGroup>
            <Button type="submit">Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </element>
  );
};

export default EditDocument;
