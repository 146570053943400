import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input ,Row ,  Alert} from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const AddExpenses = ({ addItem }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () => setModal(!modal);

  const initialFormData = { name: '' };
  const [branches, setBranches] = useState([]);
  const [sales_men, setSalesMen] = useState([]);
  const [formData, setFormData] = useState({
    branch_id: null,
    sales_man_id: null,
    date:null,
    name:null,
    email:null,
    phone:null,
    platform:null,
    category:null,
    requirement:null,
    source:null,
    asign_to:null
  });
  

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    
  };
  const user = JSON.parse(localStorage.getItem('authUser'));
  let config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = JSON.parse(localStorage.getItem('authUser'));
      let config = {
        headers: {
          'Authorization': 'Bearer ' + user.token,
        },
      };
      setShowLoader(true);
      axios.post(base_url + 'leads/add', {...formData,branch_id:formData.branch_id.value,sales_man_id:formData.sales_man_id.value}, config).then((response) => {
        const res = response.data;
        if (res.state === 1) {
          setMessage(res.msg);
          const new_item = { id: res.data.id,no: res.data.no,name: formData.name,
            branch_id: formData.branch_id.value,
            branch: formData.branch_id.label,
            sales_man_id: formData.sales_man_id.value,
            sales_man: formData.sales_man_id.label,
            date:formData.date,
            name:formData.name,
            email:formData.email,
            phone:formData.phone,
            platform:formData.platform,
            category:formData.category,
            requirement:formData.requirement,
            source:formData.source,
            asign_to:formData.asign_to
          };
          addItem(new_item);
          setShowLoader(false);
          // setFormData(initialFormData);
          setMessage('Added Successfully')
          setTimeout(() => {
            setMessage('');
            toggle();
          }, 1000);
          
        } else {
          setShowLoader(false);
          setMessage(res.msg);
        }
      });
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };

  useEffect(() => {
    axios.get(`${base_url}leads/needs`, config).then((response) => {
      const res = response.data;
      const branchesData = res.branches.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setBranches(branchesData);
      if (branchesData.length > 0) {
        setFormData((prevData) => ({ ...prevData, branch_id: branchesData[0] }));
      }
      const salesMenData = res.sales_men.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSalesMen(salesMenData);
      if (salesMenData.length > 0) {
        setFormData((prevData) => ({ ...prevData, sales_man_id: salesMenData[0] }));
      }
     
      
    });
  }, []);

  return (
    <element>
      <Button className="pullRight" color="primary" onClick={toggle}>
        +
      </Button>
      <Modal isOpen={modal} toggle={toggle} style={{ minWidth: '900px',background:'#000' }}>
        <ModalHeader toggle={toggle}>Add Lead</ModalHeader>
        <ModalBody>
            {message && <Alert color="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
           <Row>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleBranch">Branch</Label>
                        <Select
                          name="branch_id"
                          options={branches}
                          value={formData.branch_id}
                          onChange={handleSelectChange}
                        />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                    <FormGroup>
                      <Label for="exampleBranch">Salesd Man</Label>
                      <Select
                        name="sales_man_id"
                        options={sales_men}
                        value={formData.sales_man_id}
                        onChange={handleSelectChange}
                      />
                    </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Name</Label>
                        <Input id="exampleAmount" name="name" value={formData.name} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Lead Date</Label>
                        <Input id="exampleAmount" type='date' name="date" value={formData.date} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Email </Label>
                        <Input id="exampleAmount" type='email' name="email" value={formData.email} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">phone </Label>
                        <Input id="exampleAmount" type='text' name="phone" value={formData.phone} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">platform </Label>
                        <Input id="exampleAmount" type='text' name="platform" value={formData.platform} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">category </Label>
                        <Input id="exampleAmount" type='text' name="category" value={formData.category} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">source </Label>
                        <Input id="exampleAmount" type='text' name="source" value={formData.source} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Asign To </Label>
                        <Input id="exampleAmount" type='text' name="asign_to" value={formData.asign_to} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
           </Row>
            
           
            
            
            <Button>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </element>
  );
};

export default AddExpenses;
