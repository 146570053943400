import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import axios from "axios";
import { base_url } from "../../Constants";
import { Button, Alert, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import MyModal from "./MyModal";
import Add from "./Add";
import Edit from "./Edit";

const Index = (props) => {
  document.title = "All Expenses";
  const breadcrumbItems = [
    { title: "SuperOffice", link: "/" },
    { title: "Dashboard", link: "/" },
    { title: "Expenses", link: "/expenses" }
  ];

  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [flashMessage, setFlashMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  const toggleModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(!isModalOpen);
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  let config = {
    headers: {
      'Authorization': 'Bearer ' + user.token
    }
  };

  const handleDelete = () => {
    setIsModalOpen(false); 
    setShowLoader(true);
    axios.get(`${base_url}expenses/delete/${selectedItem.id}`, config).then(response => {
      if (response.data.state === 1) {
        setItems(prevItems => prevItems.filter(item => item.id !== selectedItem.id));
        setFlashMessage("Property deleted successfully!");
        setShowFlashMessage(true);
        setShowLoader(false);
        setTimeout(() => setShowFlashMessage(false), 3000); 
      }
    });
  };

  const addItem = (newItem) => {
    setItems([...items, newItem]);
  };

  const updateItem = (updatedItem) => {
    const updatedItems = items.map(item => 
      item.id === updatedItem.id ? updatedItem : item
    );
    setItems(updatedItems);
  };

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard', breadcrumbItems);
    setShowLoader(true);
    axios.get(`${base_url}expenses?page=${currentPage}`, config).then(response => {
      const res = response.data;
      setItems(res.data);  // assuming 'data' contains the list of expenses
      setTotalPages(res.meta.last_page); // assuming 'last_page' contains the total number of pages
      setShowLoader(false);
    });
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {showLoader && (
            <div id="preloader" style={{position:'absolute'}}>
              <div id="status">
                <div className="spinner-chase">
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                </div>
              </div>
            </div>
          )}

          {showFlashMessage && (
            <Alert color="success">
              {flashMessage}
            </Alert>
          )}
          <Add addItem={addItem} />
          
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Properity</th>
                <th scope="col">Amount</th>
                <th scope="col">Type</th>
                <th scope="col">Branch</th>
                <th scope="col">Date</th>
                <th scope="col">Options</th>
              </tr>
            </thead>
            <tbody>
              {items.map(item => (
                <tr key={item.id}>
                  <td>{item.no}</td>
                  <td>{item.properity}</td>
                  <td>{item.amount}</td>
                  <td>{item.expenses_type}</td>
                  <td>{item.branch}</td>
                  <td>{item.date}</td>
                  <td>
                    <Edit item={item} updateItem={updateItem} />
                    <Button color="danger" onClick={() => toggleModal(item)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <Pagination>
              <PaginationItem disabled={currentPage <= 1}>
                <PaginationLink
                  previous
                  onClick={() => handlePageChange(currentPage - 1)}
                />
              </PaginationItem>
              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem active={index + 1 === currentPage} key={index}>
                  <PaginationLink onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage >= totalPages}>
                <PaginationLink
                  next
                  onClick={() => handlePageChange(currentPage + 1)}
                />
              </PaginationItem>
            </Pagination>
          </nav>
          <MyModal
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            title="Delete Property"
            onDelete={handleDelete}
          >
            <p>Are you sure you want to delete this property?</p>
          </MyModal>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(Index);
