// Index.js
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import axios from "axios";
import { base_url } from "../../Constants";
import { Link } from "react-router-dom";
import { Button , Alert , Pagination, PaginationItem, PaginationLink, Card, CardBody, Input} from 'reactstrap';
import MyModal from "./MyModal";
 
const Index = (props) => {
  document.title = "All Properties";
  const breadcrumbItems = [
    { title: "SuperOffice", link: "#" },
    { title: "Dashboard", link: "/" },
    { title: "Properties", link: "/properties" }
  ];

  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [flashMessage, setFlashMessage] = useState("");
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showLoader, setShowLoader] = useState(true);
  const [formData,setFormData] = useState({
    name:'',
    branch_id:null
  })
  const handleChange = (e)=>{
     const {name,value} =  e.target;
     setFormData({
      ...formData,
      [name]: value
    });
  }
  const toggleModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(!isModalOpen);
  };
  const user = JSON.parse(localStorage.getItem('authUser'));
    let config = {
        headers: {
        'Authorization': 'Bearer ' + user.token
        }
    };
  const handleDelete = () => {
    axios.get(base_url+'properites/delete/'+selectedItem.id, config).then(response => {
        if (response.data.state == 1) {
            setItems(items.filter(item => item.id !== selectedItem.id));
            setIsModalOpen(false); 
            setFlashMessage("Property deleted successfully!");
            setShowFlashMessage(true);
            setTimeout(() => setShowFlashMessage(false), 3000); 
         }
      });
    
  };

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard', breadcrumbItems);
    setShowLoader(true);
    axios.get(`${base_url}properites?page=${currentPage}`, config).then(response => {
      const res = response.data;
      setItems(res.data);
      setTotalPages(res.meta.last_page);
      setShowLoader(false);
    });
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const searchSubmit = async (e)=>{
    e.preventDefault();
    setShowLoader(true);
    let config = {
      headers: {
        'Authorization': 'Bearer ' + user.token
      }
    };
    const params = new URLSearchParams(formData).toString();
    axios.get(`${base_url}properites?${params}`, config).then(response => {
      const res = response.data;
      setItems(res.data);  // assuming 'items' contains the list of expenses
      setTotalPages(res.meta.last_page); // assuming 'totalPages' contains the total number of pages
      setShowLoader(false);
    });
  }
  return (
    <React.Fragment>
       {showFlashMessage && (
        <Alert color="success">
          {flashMessage}
        </Alert>
      )}
      <Card>
        <CardBody>
        {showLoader && (
            <div id="preloader" style={{position:'absolute'}}>
              <div id="status">
                <div className="spinner-chase">
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                </div>
              </div>
            </div>
          )}

      {showFlashMessage && (
        <Alert color="success">
          {flashMessage}
        </Alert>
      )}
      <Link to={'/properities/add'} className="btn btn-primary btn-md ">+</Link>
        <form className="search-form" onSubmit={searchSubmit}>
          <div className="row">
              <div className="col-md-5">
                  <label className="control-label">Name</label>
                  <Input type="text" name="name" className="form-control" value={formData.start_date}
                    onChange={handleChange} />
              </div>
              
              <div className="col-md-5">
                    <label className="control-label">Branch</label>
                    <select name="branch_id" className="form-control" value={formData.branch_id} onChange={handleChange}>
                          <option key="0" value="">All </option>
                          <option key="3" value="1">Al Arid</option>
                          <option key="2" value="2">Al Suwaidi</option>
                          <option key="1" value="3">Al Sulimaniyah</option>
                    </select>
              </div>
              <div className="col-md-2">
              <label className="control-label">&nbsp;</label>
                <button className="btn btn-md btn-primary" style={{display:'block'}}>filter</button>
              </div>
          </div>
        </form>
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Branch</th>
            <th scope="col">Area</th>
            <th scope="col">Options</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.branch_name}</td>
              <td>{item.area}</td>
              <td>
                <Link style={{marginRight:'15px',color:'#fff'}}
                 to={`/properities/edit/${item.id}`} className=" btn btn-md btn-primary">
                  Edit
                </Link>
                <Button color="danger" onClick={() => toggleModal(item)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <nav aria-label="Page navigation example">
        <Pagination>
          <PaginationItem disabled={currentPage <= 1}>
            <PaginationLink
              previous
              onClick={() => handlePageChange(currentPage - 1)}
            />
          </PaginationItem>
          {[...Array(totalPages)].map((_, index) => (
            <PaginationItem active={index + 1 === currentPage} key={index}>
              <PaginationLink onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem disabled={currentPage >= totalPages}>
            <PaginationLink
              next
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </PaginationItem>
        </Pagination>
      </nav>
        </CardBody>
      </Card>
      
      <MyModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        title="Delete Property"
        onDelete={handleDelete}
      >
        <p>Are you sure you want to delete this property?</p>
      </MyModal>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(Index);
