import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input ,Row , Alert} from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const EditExpenses = ({ updateItem , item }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () =>{
    setModal(!modal);
    axios.get(`${base_url}leads/needs`, config).then((response) => {
      const res = response.data;
      const branchesData = res.branches.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setBranches(branchesData);
      const salesMenData = res.sales_men.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSalesMen(salesMenData);
    });
  } 

  const [branches, setBranches] = useState([]);
  const [sales_men, setSalesMen] = useState([]);
  const [formData, setFormData] = useState({
    date:item.date,
    state:item.state,
    name:item.date,
    email:item.email,
    phone:item.phone,
    platform:item.platform,
    category:item.category,
    requirement:item.requirement,
    source:item.source,
    asign_to:item.asign_to,
    branch_id: { value: item.branch_id, label: item.branch },
    sales_man_id: { value: item.sales_man_id, label: item.sales_man },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  const config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowLoader(true);
      const response = await axios.post(`${base_url}leads/edit/${item.id}`, {
        ...formData,
        branch_id: formData.branch_id.value,
        sales_man_id: formData.sales_man_id.value,
      }, config);
      const res = response.data;
      setMessage(res.msg);
      setShowLoader(false);
      if (res.state === 1) {
        updateItem({
          ...item,
          name: formData.name,
          branch_id: formData.branch_id.value,
          branch: formData.branch_id.label,
          sales_man_id: formData.sales_man_id.value,
          sales_man: formData.sales_man_id.label,
          date:formData.date,
          name:formData.name,
          email:formData.email,
          phone:formData.phone,
          platform:formData.platform,
          category:formData.category,
          requirement:formData.requirement,
          source:formData.source,
          asign_to:formData.asign_to,
        });
        setMessage('Updated Successfully');
        setTimeout(() => {
          setMessage('')
          toggle();
        }, 2000);
       
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      setShowLoader(false);
    }
  };

  // useEffect(() => {
    
  // }, []);

  return (
    <span>
      <Button style={{ marginRight: '10px' }} className="pullRight" color="primary" onClick={toggle}>
        Edit
      </Button>
      <Modal isOpen={modal} toggle={toggle} style={{minWidth:'900px'}}>
        <ModalHeader toggle={toggle}>Edit Lead</ModalHeader>
        <ModalBody>
          {message && <Alert color="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
            <Row>
                <div className='col-md-12'>
                      <FormGroup>
                        <Label for="exampleBranch">Sattus</Label>
                        <select name="state" value={formData.state} onChange={handleChange} 
                          class="form-control form-control" >
                          <option key="0" value="0">Asigned</option>
                          <option key="1" value="1">Opened</option>
                          <option key="2" value="2">Deal Done</option>
                          <option key="3" value="3">Not Done</option>
                        </select>
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleBranch">Branch</Label>
                        <Select
                          name="branch_id"
                          options={branches}
                          value={formData.branch_id}
                          onChange={handleSelectChange}
                        />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                    <FormGroup>
                      <Label for="exampleBranch">Salesd Man</Label>
                      <Select
                        name="sales_man_id"
                        options={sales_men}
                        value={formData.sales_man_id}
                        onChange={handleSelectChange}
                      />
                    </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Name</Label>
                        <Input id="exampleAmount" name="name" value={formData.name} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Lead Date</Label>
                        <Input id="exampleAmount" type='date' name="date" value={formData.date} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Email </Label>
                        <Input id="exampleAmount" type='email' name="email" value={formData.email} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">phone </Label>
                        <Input id="exampleAmount" type='text' name="phone" value={formData.phone} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">platform </Label>
                        <Input id="exampleAmount" type='text' name="platform" value={formData.platform} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">category </Label>
                        <Input id="exampleAmount" type='text' name="category" value={formData.category} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">source </Label>
                        <Input id="exampleAmount" type='text' name="source" value={formData.source} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Asign To </Label>
                        <Input id="exampleAmount" type='text' name="asign_to" value={formData.asign_to} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
           </Row>
            <Button>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </span>
  );
};

export default EditExpenses;
