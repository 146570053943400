import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBreadcrumbItems } from "../../store/actions";
import { Container, Row, Col, Card, CardBody, Label, Form, Input } from 'reactstrap';
import Select from "react-select";
import axios from "axios";
import { base_url,rentTypes } from "../../Constants";
import AddClient from "../Clients/AddSelect";
const Add = (props) => {
  document.title = "All Rents";

  const breadcrumbItems = [
    { title: "SuperOffice", link: "#" },
    { title: "Dashboard", link: "/dashboard" },
    { title: "Rents", link: "/rents" },
    { title: "New Rents", link: "/rents/add" }
  ];

  const formatDate = (date) => {
    const pad = (num) => (num < 10 ? '0' : '') + num;
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const today = new Date();
  const formattedDate = formatDate(today);

  const [formData, setFormData] = useState({
    properity_id: null,
    branch_id: null,
    client_id: null,
    sales_man_id: null,
    duration_id:null,
    area: 1,
    amount: 1,
    sqm_price: 1,
    channel: null,
    contract_no: null,
    date: formattedDate,
    end_date:formattedDate,
    renewal_date:null,
    company_name:null,
    cr_no:null,
    client_name:null,
    id_no:null,
    phone:null,
    note:null,
    rent_type:null
  });
  const [message, setMessage] = useState('');
  const [branches, setBranches] = useState([]);
  const [properities, setProperities] = useState([]);
  const [clients, setClients] = useState([]);
  const [sales_men, setSalesMen] = useState([]);
  const [durations, setDurations] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();
  const addItem = (newItem) => {
    setClients([...clients, newItem]);
    setFormData((prevData) => ({ ...prevData, client_id: newItem }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const user = JSON.parse(localStorage.getItem('authUser'));
  let config = {
    headers: {
      'Authorization': 'Bearer ' + user.token
    }
  };
  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    if (name === 'branch_id') {
      handleBranchChange(selectedOption);
    }
  };

  const handleBranchChange = (selectedOption) => {
    axios.get(`${base_url}rents/needs?branch_id=${selectedOption.value}`, config).then((response) => {
      const res = response.data;

      const properitiesData = res.properities.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setProperities(properitiesData);
      if (properitiesData.length > 0) {
        setFormData((prevData) => ({ ...prevData, properity_id: properitiesData[0] }));
      }
    });
  };

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard', breadcrumbItems);
    axios.get(`${base_url}rents/needs`, config).then(response => {
      const res = response.data;
      const branchesData = res.branches.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setBranches(branchesData);
      if (branchesData.length > 0) {
        setFormData((prevData) => ({ ...prevData, branch_id: branchesData[0] }));
      }
      const properitiesData = res.properities.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setProperities(properitiesData);
      if (properitiesData.length > 0) {
        setFormData((prevData) => ({ ...prevData, properity_id: properitiesData[0] }));
      }
      const clientsData = res.clients.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setClients(clientsData);
      if (clientsData.length > 0) {
        setFormData((prevData) => ({ ...prevData, client_id: clientsData[0] }));
      }
      const salesData = res.sales_men.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSalesMen(salesData);
      if (salesData.length > 0) {
        setFormData((prevData) => ({ ...prevData, sales_man_id: salesData[0] }));
      }
      const durationData = res.durations.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setDurations(durationData);
      if (durationData.length > 0) {
        setFormData((prevData) => ({ ...prevData, duration_id: durationData[0] }));
      }
    });
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    try { 
      axios.post(`${base_url}rents/add`, {
        ...formData,
        branch_id: formData.branch_id.value,
        properity_id: formData.properity_id.value,
        sales_man_id: formData.sales_man_id.value,
        client_id: formData.client_id.value,
        duration_id: formData.duration_id.value,
      }, config).then(response => {
        const res = response.data;
        if (res.state === 1) {
          setMessage(res.msg);
          setShowLoader(false);
          setTimeout(()=>{
            navigate('/rents');
          },1000)
        } else {
          setShowLoader(false);
          setMessage(res.msg);
        }
      });
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };

  return (
    <React.Fragment>
        <Card className="overflow-hidden">
          <CardBody className="pt-0">
            <div className="p-3">
              <Form
                className="form-horizontal mt-4"
              >
                {message && <h3>{message}</h3>}
                {showLoader && (
                  <div id="preloader" style={{ position: 'absolute' }}>
                    <div id="status">
                      <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                <div className="mb-3 col-md-12">
                      <Label htmlFor="username">Rent Type</Label>
                      <select
                        name="rent_type"
                        value={formData.rent_type}
                        onChange={handleChange}
                        className="form-control form-control"
                      >
                        {rentTypes.map((rentType) => (
                          <option key={rentType.id} value={rentType.id}>
                            {rentType.name}
                          </option>
                        ))}
                      </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="branch_id">Branch</Label>
                    <Select
                      name="branch_id"
                      options={branches}
                      value={formData.branch_id}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="properity_id">Office</Label>
                    <Select
                      name="properity_id"
                      options={properities}
                      value={formData.properity_id}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="client_id">Clients</Label>
                    <div className="plus-div"> 
                        <Select
                          name="client_id"
                          options={clients}
                          value={formData.client_id}
                          onChange={handleSelectChange}
                        />
                        <AddClient addItem={addItem} />
                    </div>
                    
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="sales_man_id">Employee</Label>
                    <Select
                      name="sales_man_id"
                      options={sales_men}
                      value={formData.sales_man_id}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="duration_id">Months Count</Label>
                    <Select
                      name="duration_id"
                      options={durations}
                      value={formData.duration_id}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="amount">Area</Label>
                    <Input
                      name="area"
                      className="form-control"
                      type="number"
                      step="any"
                      value={formData.area}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="amount">Amount</Label>
                    <Input
                      name="amount"
                      className="form-control"
                      type="number"
                      step="any"
                      value={formData.amount}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="sqm_price">SQM price</Label>
                    <Input
                      name="sqm_price"
                      className="form-control"
                      placeholder="Enter SQM price"
                      type="number"
                      step="any"
                      value={formData.sqm_price}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="type">Type</Label>
                    <select name="type" value={formData.type} onChange={handleChange}
                      className="form-control">
                      <option key="0" value="0">New</option>
                      <option key="1" value="1">Renew</option>
                    </select>
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="channel">Channel</Label>
                    <Input
                      name="channel"
                      className="form-control"
                      type="text"
                      value={formData.channel}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="date">Date</Label>
                    <Input
                      name="date"
                      className="form-control"
                      type="datetime-local"
                      value={formData.date}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="date">End Date</Label>
                    <Input
                      name="end_date"
                      className="form-control"
                      type="datetime-local"
                      value={formData.end_date}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="date">Renewal Date</Label>
                    <Input
                      name="renewal_date"
                      className="form-control"
                      type="date"
                      value={formData.renewal_date}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="amount">Company name</Label>
                    <Input
                      name="company_name"
                      className="form-control"
                      type="text"
                      value={formData.company_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="amount">CR Number</Label>
                    <Input
                      name="cr_no"
                      className="form-control"
                      type="text"
                      value={formData.cr_no}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="amount">Client Name</Label>
                    <Input
                      name="client_name"
                      className="form-control"
                      type="text"
                      value={formData.client_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="amount">ID Number</Label>
                    <Input
                      name="id_no"
                      className="form-control"
                      type="text"
                      value={formData.id_no}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <Label htmlFor="amount">Phone Number</Label>
                    <Input
                      name="phone"
                      className="form-control"
                      type="text"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3 col-md-12">
                    <Label htmlFor="amount">Notes</Label>
                    <Input
                      name="note"
                      className="form-control"
                      type="textarea"
                      rows="3"
                      step="any"
                      value={formData.note}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <Row className="mb-3 mt-4">
                  <div className="col-6 text-end">
                    <button className="btn btn-primary w-md waves-effect waves-light" onClick={handleSubmit} type="submit">Submit</button>
                  </div>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(Add);