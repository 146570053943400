import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard
import Dashboard from "../pages/Dashboard/adminHome"


//Extra Pages
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";

//branches 
import BranchIndex from "../pages/Branch/Index";
import BranchAdd from "../pages/Branch/Add";
import BranchEdit from "../pages/Branch/Edit";
//endbranches

//properities 
import ProperityIndex from "../pages/Properity/Index";
import ProperityAdd from "../pages/Properity/Add";
import ProperityEdit from "../pages/Properity/Edit";
//endbranches

//expenses types
import ExpenseTypeIndex from "../pages/ExpensesType/Index";
import ExpenseTypeAdd from "../pages/ExpensesType/Add";
import ExpenseTypeEdit from "../pages/ExpensesType/Edit";
//expenses
import ExpensesIndex from "../pages/Expenses/Index";
//end expenses types
//clients
import ClientIndex from "../pages/Clients/Index";
//sales-men
import  SalesMen from "../pages/Salesmen/Index";
//durations
import DurationIndex from "../pages/durations/Index";
//documents
import DocumentIndex from "../pages/Documents/Index";
//rents 
import  RentIndex from  "../pages/Rents/Index";
import  RentAdd from "../pages/Rents/Add";
import  RentEdit  from  "../pages/Rents/Edit";
import  Renewal   from  "../pages/Rents/Renewal";
//users
import UserIndex from "../pages/Users/Index";
//leads
import LeadIndex from "../pages/Leads/Index";
//customerform
import CustomerFormIndex from "../pages/CustomerForms/Index";
//payments
import PaymentIndex from "../pages/Payments/Index"
//end expenses types
//accounts
import AccountIndex from "../pages/Account/Index";
import AccountShow  from "../pages/Account/Show";

import { components } from "react-select"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  
  //rents
  { path: "/rents", component: <RentIndex /> },
  { path: "/renewals", component: <Renewal /> },
  { path: "/rents/add", component: <RentAdd /> },
  { path: "/rents/edit/:id", component: <RentEdit /> },
  //leads
  { path: "/leads", component: <LeadIndex /> },
  //cutomer-forms
  {path:"/customer-forms",component:<CustomerFormIndex />},
  //users
  { path: "/users", component: <UserIndex /> },
  // // //profile
  { path: "/profile", component: <UserProfile /> },

  { path: "/pages-blank", component: <PagesBlank /> },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  

]
const adminRoutes = [
  //branches
  { path: "/branches", component: <BranchIndex /> },
  { path: "/branches/add", component: <BranchAdd /> },
  { path: "/branches/edit/:id", component: <BranchEdit /> },

  //properities
  { path: "/properities", component: <ProperityIndex /> },
  { path: "/properities/add", component: <ProperityAdd /> },
  { path: "/properities/edit/:id", component: <ProperityEdit /> },

  //expenses-types
  { path: "/expenses-types", component: <ExpenseTypeIndex /> },
  { path: "/expenses", component: <ExpensesIndex /> },
  { path: "/payments", component: <PaymentIndex /> },
  {path: "/accounts",component:<AccountIndex /> },
  {path: "/accounts/show/:id",component:<AccountShow /> },
  { path: "/expenses-types/edit/:id", component: <ExpenseTypeEdit /> },
  //clients
  { path: "/clients", component: <ClientIndex /> },
  //sales-men
  { path: "/sales-men", component: <SalesMen /> },
  //durations
  { path: "/durations", component: <DurationIndex /> },
  // Documents
  { path: "/documents", component: <DocumentIndex /> },
]
const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes, adminRoutes }