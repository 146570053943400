import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,FormGroup,Form,Row,Label,Input, Col } from 'reactstrap';
import axios from 'axios';
import { base_url } from "../../Constants";
const AddExpensesType =  ({addItem})=>{
  const [modal, setModal] = useState(false);
  const [message,setMessage] = useState('');
  const [showLoader,setShowLoader] = useState(false);
  const toggle = () => setModal(!modal);
  const initialFormData = { name: '' };
  const [formData, setFormData] = useState({
    name: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const  user =  JSON.parse(localStorage.getItem('authUser'));
        let config = {
            headers: {
              'Authorization': 'Bearer ' + user.token
            }
          }
        setShowLoader(true);
        axios.post(base_url+'expenses-types/add',formData,config).then(response=>{
            const res =  response.data;
            if (res.state == 1) {
                setMessage(res.msg);
                const new_item  = {'id':res.data.id,'name':res.data.name};
                addItem(new_item);
                setShowLoader(false);
                setFormData(initialFormData); 
                toggle();
                
            }else{
                setMessage(res.msg); 
            }
        })
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };
  return (
    <div>
      <Button className='pull-right' color="primary" onClick={toggle}>
        +
      </Button>
      <Modal isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}>Add Expenses Type</ModalHeader>
        <ModalBody>
        {message && <h3>{message}</h3>}
        <Form 
            onSubmit={handleSubmit}
            
            >
            {showLoader &&(
                <div id="preloader" style={{position:'absolute'}}>
                  <div id="status">
                    <div className="spinner-chase">
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                    </div>
                  </div>
                </div>
            )}
            <FormGroup>
                <Label for="exampleAddress">
                   Name
                </Label>
                <Input
                id="exampleAddress"
                name="name"
                value={formData.name} onChange={handleChange} 
                placeholder=""
                />
            </FormGroup>
            <Button>
                Submit
            </Button>
        </Form>
        </ModalBody>
        
      </Modal>
    </div>
  );
}

export default AddExpensesType;