import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input ,Row , Alert} from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const EditExpenses = ({ updateItem , item }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () =>{
    setModal(!modal);
   
  } 

  const [formData, setFormData] = useState({
    password:null,
    confirm_password:null,
    type:item.type,
    name:item.name,
    email:item.email,    
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  const config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowLoader(true);
      const response = await axios.post(`${base_url}users/edit/${item.id}`,
         {...formData}, config);
         const res = response.data;
      setMessage(res.msg);
      setShowLoader(false);
      if (res.state === 1) {
        updateItem({
          ...item,
          name: formData.name,
          email: formData.email,
          type: formData.type,
          type_name: res.data.type_name         
        });
        setMessage('Updated Successfully');
        setTimeout(() => {
          setMessage('')
          toggle();
        }, 2000);
       
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      setShowLoader(false);
    }
  };

  // useEffect(() => {
    
  // }, []);

  return (
    <span>
      <Button style={{ marginRight: '10px' }} className="pullRight" color="primary" onClick={toggle}>
        Edit
      </Button>
      <Modal isOpen={modal} toggle={toggle} style={{minWidth:'900px'}}>
        <ModalHeader toggle={toggle}>Edit User</ModalHeader>
        <ModalBody>
          {message && <Alert color="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
            <Row>
                
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Name</Label>
                        <Input id="exampleAmount" name="name" value={formData.name} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
              
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Email </Label>
                        <Input id="exampleAmount" type='email' name="email" value={formData.email}
                         onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-12'>
                      <FormGroup>
                        <Label for="exampleBranch">Sattus</Label>
                        <select name="type" value={formData.type} onChange={handleChange} 
                          class="form-control form-control" >
                          <option key="0" value="0">Admin</option>
                          <option key="1" value="1">Employee</option>
                        </select>
                      </FormGroup>
                </div>
               
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Password </Label>
                        <Input id="exampleAmount" type='password' name="password"
                         value={formData.password} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Confirm Password </Label>
                        <Input id="exampleAmount" type='password' 
                        name="confirm_password" value={formData.confirm_password} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
           </Row>
            <Button>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </span>
  );
};

export default EditExpenses;
