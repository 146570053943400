import React , {useEffect,useState} from "react"

import { connect } from "react-redux";



//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import axios from "axios";
import { base_url } from "../../Constants";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
const Index = (props) => {
  document.title = "All Branches";
  const breadcrumbItems = [
    { title: "SuperOffice", link: "#" },
    { title: "Dashboard", link: "/dashboard" },
    { title: "Branches", link: "/all Branches" }
  ];
  const [items,setItems] =  useState([]);
  useEffect(() => {
        props.setBreadcrumbItems('Dashboard' , breadcrumbItems);
        const  user =  JSON.parse(localStorage.getItem('authUser'));
        let config = {
            headers: {
              'Authorization': 'Bearer ' + user.token
            }
          }
        axios.get(base_url+'branches',config).then(response=>{
                const res =  response.data;
                setItems(res.data);
        });
  },[])

 

  return (
    <React.Fragment>
      <Card>
        <CardBody>
        <Link to={'/branches/add'} className="btn btn-primary btn-md ">Add</Link>
        <table className="table">
            <thead className="thead-dark">
            <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Options</th>
            </tr>
            </thead>
            <tbody>
                { items.map(item=>(
                    <tr key={item.id}>
                        <td>
                            {item.id}
                        </td>
                        <td>
                            {item.name}
                        </td>
                        <td>
                        <Link to={`/branches/edit/${item.id}`}
                        name={item.name}
                        id={item.id}
                         className="text-muted btn btn-md btn-primary">
                            Edit
                         </Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        </CardBody>
      </Card>
        
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Index);