import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import axios from "axios";
import { base_url } from "../../Constants";
import { Link, useParams } from "react-router-dom";
import { Button, Alert, Card, CardBody } from 'reactstrap';
import Popup from '../Popups/Popup'; // Import the Popup component

const Show = (props) => {
  const { id } = useParams();
  document.title = "Account Show";
  const [data, setData] = useState({});
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [flashMessage, setFlashMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [showFlashMessage, setShowFlashMessage] = useState(false);

  const breadcrumbItems = [
    { title: "SuperOffice", link: "#" },
    { title: "Dashboard", link: "/" },
    { title: "Accounts", link: "/accounts" },
    { title: data.name, link: "/accounts/show/" + id }
  ];

  const toggleModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(!isModalOpen);
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  let config = {
    headers: {
      'Authorization': 'Bearer ' + user.token
    }
  };

  const addItem = (newItem) => {
    setItems([...items, newItem]);
  };

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard', breadcrumbItems);
    const user = JSON.parse(localStorage.getItem('authUser'));
    let config = {
      headers: {
        'Authorization': 'Bearer ' + user.token
      }
    };
    axios.get(base_url + 'accounts/single/' + id, config).then(response => {
      const res = response.data;
      setItems(res.items);
      setData(res.data);
      setShowLoader(false);
    });
  }, []);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {showLoader && (
            <div id="preloader" style={{ position: 'absolute' }}>
              <div id="status">
                <div className="spinner-chase">
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                </div>
              </div>
            </div>
          )}

          {showFlashMessage && (
            <Alert color="success">
              {flashMessage}
            </Alert>
          )}
          <h3>
            Account: {data.name}
          </h3>
          <h3>
            Total Amount: {data.amount}
          </h3>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Action</th>
                <th scope="col">In</th>
                <th scope="col">Out</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {items.map(item => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    <Link to="#" onClick={() => toggleModal(item)} >
                      {item.name}
                    </Link>
                  </td>
                  <td>{item.in}</td>
                  <td>{item.out}</td>
                  <td>{item.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>

      <Popup
        item={selectedItem}
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(Show);
