import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const AddExpenses = ({ addItem }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () => setModal(!modal);

  const initialFormData = { name: '' };
  const [branches, setBranches] = useState([]);
  const [properities, setProperities] = useState([]);
  const [expenses_types, setExpensesTypes] = useState([]);
  const [formData, setFormData] = useState({
    amount: 1,
    branch_id: null,
    properity_id: null,
    expenses_type_id:null,
  });
  const [formData2, setFormData2] = useState({
    amount: 1,
    branch_id: null,
    properity_id: null,
    expenses_type_id:null,
  });

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
      setFormData2({
        ...formData2,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData2({
      ...formData2,
      [name]: selectedOption,
    });
    setFormData({
      ...formData,
      [name]: selectedOption.value,
    });
    if (name === 'branch_id') {
      handleBranchChange(selectedOption);
    }
  };
  const user = JSON.parse(localStorage.getItem('authUser'));
  let config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };
  const  handleBranchChange = (selectedOption)=>{
    
    axios.get(base_url+'expenses/needs?branch_id='+selectedOption.value,config).then((response) => {
      const res = response.data;
      
      const properitiesData = res.properities.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setProperities(properitiesData);
      if (properitiesData.length > 0) {
        setFormData((prevData) => ({ ...prevData, properity_id: null }));
        setFormData2((prevData) => ({ ...prevData, properity_id: null }));
      }
    }); 
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = JSON.parse(localStorage.getItem('authUser'));
      let config = {
        headers: {
          'Authorization': 'Bearer ' + user.token,
        },
      };
      setShowLoader(true);
      axios.post(base_url + 'expenses/add', formData, config).then((response) => {
        const res = response.data;
        if (res.state === 1) {
          setMessage(res.msg);
          const new_item = { id: res.data.id,no: res.data.no,amount: res.data.amount,
             branch: res.data.branch,expenses_type: res.data.expenses_type,properity: res.data.properity };
          addItem(new_item);
          setShowLoader(false);
          // setFormData(initialFormData);
          toggle();
        } else {
          setShowLoader(false);
          setMessage(res.msg);
        }
      });
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };

  useEffect(() => {
    
    axios.get(`${base_url}expenses/needs`, config).then((response) => {
      const res = response.data;
      const branchesData = res.branches.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setBranches(branchesData);
      if (branchesData.length > 0) {
        setFormData2((prevData) => ({ ...prevData, branch_id: branchesData[0] }));
        setFormData((prevData) => ({ ...prevData, branch_id: branchesData[0].value }));
      }
      const typesData = res.types.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setExpensesTypes(typesData);
      if (typesData.length > 0) {
        setFormData((prevData) => ({ ...prevData, expenses_type_id: typesData[0].value }));
        setFormData2((prevData) => ({ ...prevData, expenses_type_id: typesData[0] }));
      }
      const properitiesData = res.properities.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setProperities(properitiesData);
      // if (properitiesData.length > 0) {
      //   setFormData2((prevData) => ({ ...prevData, properity_id: properitiesData[0] }));
      //   setFormData((prevData) => ({ ...prevData, properity_id: properitiesData[0].value }));
      // }
    });
  }, []);

  return (
    <div>
      <Button className="pullRight" color="primary" onClick={toggle}>
        +
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Expenses</ModalHeader>
        <ModalBody>
          {message && <h3>{message}</h3>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
            <FormGroup>
              <Label for="exampleProperity">Expenses type</Label>
              <Select
                name="expenses_type_id"
                options={expenses_types}
                value={formData2.expenses_type_id}
                onChange={handleSelectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">Amount</Label>
              <Input id="exampleAmount" name="amount" value={formData.amount} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleBranch">Branch</Label>
              <Select
                name="branch_id"
                options={branches}
                value={formData2.branch_id}
                onChange={handleSelectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleProperity">Properity</Label>
              <Select
                name="properity_id"
                options={properities}
                value={formData2.properity_id}
                onChange={handleSelectChange}
              />
            </FormGroup>
            <Button>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddExpenses;
