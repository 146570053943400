export  const base_url =  'https://admin.superoffice.sa/api/';
export  const branches = [
  { label: 'Al Arid', value: 'al_arid' },
  { label: 'Al Suwaidi', value: 'al_suwaidi' },
  { label: 'Al Sulimaniyah', value: 'al_sulimaniyah' }
]; 
export  const contact_ways = [
  { label: 'vist', value: 'vist' },
  { label: 'call', value: 'call' },
  { label: 'whatsapp or other messages', value: 'whatsapp or other messages' }
];
export const products =  [
      { label: 'private office', value: 'private office' },
      { label: 'parking', value: 'parking' },
      { label: 'warehouse', value: 'warehouse' },
      { label: 'meeting rooms', value: 'meeting rooms' },
      { label: 'virual offices', value: 'virual offices' },
      { label: 'co-working spaces', value: 'co-working spaces' },
      { label: 'others', value: 'others' },
];
export const coming_froms = [
  { label: 'تجديد Renewal', value: 'renewal' },
  { label: 'صديق Friend', value: 'friend' },
  { label: 'فيسبوك Facebook', value: 'facebook' },
  { label: 'مرجان Mourjan', value: 'mourjan' },
  { label: 'عقار Aqar', value: 'aqar' },
  { label: 'السوق المفتوح Open Sooq', value: 'open_sooq' },
  { label: 'انستقرام Instagram', value: 'instagram' },
  { label: 'تويتر Twitter', value: 'twitter' },
  { label: 'سناب شات Snapchat', value: 'snapchat' },
  { label: 'حراج Haraj', value: 'haraj' },
  { label: 'زاهب Zaahib', value: 'zaahib' },
  { label: 'اوليكس OLX', value: 'olx' },
  { label: 'بيزات Bezaat', value: 'bezaat' },
  { label: 'عقار ماب Aqarmap', value: 'aqarmap' },
  { label: 'قوقل Google', value: 'google' },
  { label: 'للعرب LelArab', value: 'lelarab' },
  { label: 'السوق المميز VIP Sook', value: 'vip_sook' },
  { label: 'مزادكم Mazadoka', value: 'mazadoka' },
  { label: 'تليقرام Telegram', value: 'telegram' },
  { label: 'لينكدان LinkedIn', value: 'linkedin' },
  { label: 'سوق 24 Soq 24', value: 'soq_24' },
  { label: 'سوق الخليجي Souqalkhalej', value: 'souqalkhalej' },
  { label: 'عرض وطلب Ardwatalab', value: 'ardwatalab' },
  { label: 'خرائط قوقل Google Map', value: 'google_map' },
  { label: 'يوتيوب YouTube', value: 'youtube' },
  { label: 'تيك توك TikTok', value: 'tiktok' },
  { label: 'ديل Deal', value: 'deal' },
  { label: 'انستانت اوفيس Instant Offices', value: 'instant_offices' },
  { label: 'رووف اوفيس Roof Offices', value: 'roof_offices' },
  { label: 'اللوحة الخارجية Outdoor Board', value: 'outdoor_board' },
  { label: 'مستعمل Used', value: 'used' },
  { label: 'بيوت Bayut', value: 'bayut' },
  { label: 'SMS', value: 'sms' },
  { label: 'Email', value: 'email' },
  { label: 'بينترست Pinterest', value: 'pinterest' },
  { label: 'ابواب Abwab', value: 'abwab' },
  { label: 'بازار Bazaar', value: 'bazaar' },
  { label: 'Sell2day', value: 'sell2day' },
  { label: 'كل شيء Everything', value: 'everything' },
  { label: 'خيار 41 Option 41', value: 'option_41' },
  { label: 'إعلانات كوم Ads Kom', value: 'ads_kom' },
  { label: 'وسيط نت Wasit Net', value: 'wasit_net' },
  { label: 'مزادوكا Mazadoka', value: 'mazadoka' },
  { label: 'شباك Shobak', value: 'shobak' },
  { label: 'عقارات Real Estate', value: 'real_estate' },
  { label: '14 top', value: '14_top' },
  { label: 'سوق الجمعة Friday Market', value: 'friday_market' },
  { label: 'مساكن Housing', value: 'housing' },
  { label: 'مسكن Home', value: 'home' },
  { label: 'مزادك Your Auction', value: 'your_auction' },
  { label: 'سوق الإعلانات Ads Market', value: 'ads_market' },
  { label: 'أجرها Rent It', value: 'rent_it' },
  { label: 'Aqar easy', value: 'aqar_easy' },
  { label: 'Property Finder', value: 'property_finder' },
  { label: 'WhatsApp', value: 'whatsapp' },
  { label: 'عقاراتكم Your Real Estate', value: 'your_real_estate' },
  { label: 'Other', value: 'other' }
];
export const rented_before_options = [
  { label: 'نعم Yes', value: 'yes' },
  { label: 'لا No', value: 'no' },
  { label: 'تجديد Renewal', value: 'renewal' },
  { label: 'Other', value: 'other' }
];


export const rentTypes = [
    {
      id:0,name:'parking',
    },
    {
      id:1,name:'warehouse',
    },
    {
      id:2,name:'meeting rooms',
    },
    {
      id:3,name:'virual offices',
    },
    {
      id:4,name:'other contracts',
    },
    {
      id:5,name:'renewals',
    }
  ];