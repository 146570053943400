import React, { Component } from 'react';

import C3Chart from 'react-c3js';
import 'c3/c3.css';

const AccountDonut = ({account})=>{

        const data = {
            columns: [
                ['Payments', account.payments],
                ['Expenses', account.expenses],
                ['rents', account.rents]
            ],
            type: "donut",
        };

        const donut = {
            title: "Current Month ",
            width: 30,
            label: { show: !1 }
        };

        const color = {
            pattern: ['#58db83', '#7a6fbe', '#28bbe3']
        };

        const size = {
            height: 300
        };

        return (
            <React.Fragment>
                <C3Chart data={data} donut={donut} color={color} size={size} dir="ltr" />
            </React.Fragment>
        );
    
}

export default AccountDonut;