import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const EditClient = ({ updateItem , item }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  } 

  const [formData, setFormData] = useState({
    name: item.name,
    duration: item.duration,
    type: item.type,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  const config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowLoader(true);
      const response = await axios.post(`${base_url}durations/edit/${item.id}`,formData, config);
      const res = response.data;
      setMessage(res.msg);
      setShowLoader(false);
      if (res.state === 1) {
        updateItem({
          ...item,
          name: formData.name,
          duration: formData.duration,
          type: formData.type,
          type_name: res.data.type_name,
         
        });
        toggle();
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      setShowLoader(false);
    }
  };

  // useEffect(() => {
    
  // }, []);

  return (
    <span>
      <Button style={{ marginRight: '10px' }} className="pullRight" color="primary" onClick={toggle}>
        Edit
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{ item.name }</ModalHeader>
        <ModalBody>
          {message && <h3>{message}</h3>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
            <FormGroup>
              <Label for="exampleAmount">Name</Label>
              <Input id="exampleAmount" name="name" required value={formData.name} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">Duration</Label>
              <Input id="exampleAmount" type="number" step="any" required name="duration" value={formData.duration} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
            <Label htmlFor="username">Type</Label>
              <select name="type" value={formData.type} onChange={handleChange} 
                class="form-control form-control" >
                <option key="0" value="0">Per Day</option>
                <option key="1" value="1">Per Hours</option>
              </select>
            </FormGroup>
            <Button>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </span>
  );
};

export default EditClient;
