import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import axios from "axios";
import { base_url } from "../../Constants";
import { Button, Alert, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import { Link } from "react-router-dom";
const Index = (props) => {
  document.title = "Accounts";
  const breadcrumbItems = [
    { title: "SuperOffice", link: "/" },
    { title: "Dashboard", link: "/" },
    { title: "Accounts", link: "/accounts" }
  ];

  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [flashMessage, setFlashMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  const toggleModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(!isModalOpen);
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  let config = {
    headers: {
      'Authorization': 'Bearer ' + user.token
    }
  };

  

  

  const addItem = (newItem) => {
    setItems([...items, newItem]);
  };

  const updateItem = (updatedItem) => {
    const updatedItems = items.map(item => 
      item.id === updatedItem.id ? updatedItem : item
    );
    setItems(updatedItems);
  };

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard', breadcrumbItems);
    setShowLoader(true);
    axios.get(`${base_url}accounts?page=${currentPage}`, config).then(response => {
      const res = response.data;
      setItems(res.data);
      setTotalPages(res.meta.last_page);
      setShowLoader(false);
    });
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {showLoader && (
            <div id="preloader" style={{position:'absolute'}}>
              <div id="status">
                <div className="spinner-chase">
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                </div>
              </div>
            </div>
          )}

          {showFlashMessage && (
            <Alert color="success">
              {flashMessage}
            </Alert>
          )}
          
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Amount</th>
                <th scope="col">Options</th>
              </tr>
            </thead>
            <tbody>
              {items.map(item => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.amount}</td>
                  <td>
                      <Link to={`/accounts/show/${item.id}`}
                          name={item.name}
                          id={item.id}
                          className="text-muted btn btn-md btn-primary">
                             <i className="fas fa-eye white"></i>
                      </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <Pagination>
              <PaginationItem disabled={currentPage <= 1}>
                <PaginationLink
                  previous
                  onClick={() => handlePageChange(currentPage - 1)}
                />
              </PaginationItem>
              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem active={index + 1 === currentPage} key={index}>
                  <PaginationLink onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage >= totalPages}>
                <PaginationLink
                  next
                  onClick={() => handlePageChange(currentPage + 1)}
                />
              </PaginationItem>
            </Pagination>
          </nav>
          
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(Index);
