import React , {useEffect,useState} from "react"

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { Container, Row,Col,Modal, ModalBody, Card, CardBody, Label, Form, Alert, Input, FormFeedback } from 'reactstrap';
import { Link } from 'react-router-dom';

import axios from "axios";
import { base_url } from "../../Constants";
import { set } from "lodash";
const Add = (props) => {
  document.title = "Add Office";
  const breadcrumbItems = [
    { title: "SuperOffice", link: "#" },
    { title: "Dashboard", link: "/dashboard" },
    { title: "Add Office", link: "/properities/add" }
  ];
  const [formData, setFormData] = useState({
    name: '',
    branch_id:'',
    area:1
  });
  const [message,setMessage] = useState('');
  const [branches,setBranches] =  useState([]);
  const [showLoader,setShowLoader] = useState(false);
  const navigate = useNavigate(); 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const  user =  JSON.parse(localStorage.getItem('authUser'));
  let config = {
      headers: {
        'Authorization': 'Bearer ' + user.token
      }
    }
  useEffect(() => {
        props.setBreadcrumbItems('Dashboard' , breadcrumbItems); 
        axios.get(base_url+'branches',config).then(response=>{
          const res =  response.data;
          setFormData({
            ...formData,
            ['branch_id']: res.data[0].id
          });
          setBranches(res.data);
        })
  },[])
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    try {
        axios.post(base_url+'properites/add',formData,config).then(response=>{
            const res =  response.data;
            if (res.state == 1) {
                setMessage(res.msg);
                navigate('/properities');
            }else{
               
                setMessage(res.msg); 
            }
        })
        setShowLoader(false)
    } catch (error) {
      setShowLoader(false)
      console.error('There was an error submitting the form!', error);
    }
  };
 

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
          <CardBody className="pt-0">
              <div className="p-3">
                  <Form
                    className="form-horizontal mt-4"
                    onSubmit={handleSubmit}
                  > 
                  {message && <h3>{message}</h3>}
                  {showLoader && (
                  <div id="preloader" style={{ position: 'absolute' }}>
                    <div id="status">
                      <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                      </div>
                    </div>
                  </div>
                )}
                  <div class="row">
                    <div className="mb-3 col-md-6">
                          <Label htmlFor="username">Name</Label>
                          <Input
                            name="name"
                            className="form-control"
                            placeholder="Enter name"
                            type="text"
                            value={formData.name} onChange={handleChange} 
                          />
                          
                      </div>
                      <div className="mb-3 col-md-6">
                          <Label htmlFor="username">Area</Label>
                          <Input
                            name="area"
                            className="form-control"
                            placeholder="Enter area"
                            type="number"
                            value={formData.area} onChange={handleChange} 
                          />
                      </div>
                      <div className="mb-3 col-md-12">
                          <Label htmlFor="username">Branch</Label>
                          <select name="branch_id" value={formData.branch_id} onChange={handleChange} 
                            class="form-control form-control" >
                            {
                              branches.map(branch=>(<option key={branch.id} value={branch.id}>{branch.name}</option>))
                            }
                            
                            
                          </select>
                      </div>
                    </div>
                      
                      <Row className="mb-3 mt-4">
                          
                          <div className="col-6 text-end">
                              <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Submit</button>
                          </div>
                      </Row>
                  </Form>
              </div>
          </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Add);