import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input ,Row ,  Alert} from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url , branches , coming_froms, contact_ways, products, rented_before_options } from "../../Constants";
import { any } from 'prop-types';

const Edit = ({ updateItem,item }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () => setModal(!modal);

  const [formData, setFormData] = useState({
    location: {label:item.location,value:item.location},
    date:item.date,
    name:item.name,
    phone:item.phone,
    contact_way:{label:item.contact_way,value:item.contact_way},
    product:{label:item.product,value:item.product},
    size:item.size,
    coming_from:{label:item.coming_from,value:item.coming_from},
    rented_before:{label:item.rented_before,value:item.rented_before}
  });
  

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    
  };
  const user = JSON.parse(localStorage.getItem('authUser'));
  let config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = JSON.parse(localStorage.getItem('authUser'));
      let config = {
        headers: {
          'Authorization': 'Bearer ' + user.token,
        },
      };
      setShowLoader(true);
      axios.post(base_url + 'customer-form/edit/'+item.id, {...formData,location:formData.location.label,
        coming_from:formData.coming_from.label,contact_way:formData.contact_way.label
        ,product:formData.product.label,rented_before:formData.rented_before.label}, config).then((response) => {
        const res = response.data;
        if (res.state === 1) {
          setMessage(res.msg);
          
          updateItem({
            ...item,location: res.data.location,
            date:res.data.date,
            name:res.data.name,
            phone:res.data.phone,
            contact_way:res.data.contact_way,
            product:res.data.product,
            size:res.data.size,
            coming_from:res.data.coming_from,
            rented_before:res.data.rented_before
          }); 
          setShowLoader(false);
          // setFormData(initialFormData);
          setMessage('Updated Successfully') 
          setTimeout(() => {
            setMessage('');
            toggle();
          }, 1000);
          
        } else {
          setShowLoader(false);
          setMessage(res.msg);
        }
      });
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };


  return (
    <element>
      <Button className="pullRight margin-right " color="primary" onClick={toggle}>
        Edit
      </Button>
      <Modal isOpen={modal} toggle={toggle} style={{ minWidth: '900px',background:'#000' }}>
        <ModalHeader toggle={toggle}>Update Customer Form</ModalHeader>
        <ModalBody>
            {message && <Alert color="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
           <Row>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Name</Label>
                        <Input  required id="exampleAmount" name="name" value={formData.name} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">phone </Label>
                        <Input  required id="exampleAmount" type='text' name="phone" value={formData.phone} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleBranch">Branch</Label>
                        <Select required
                          name="location"
                          options={branches}
                          value={formData.location}
                          onChange={handleSelectChange}
                        />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount"> Date</Label>
                        <Input  required id="exampleAmount" type='date' name="date" value={formData.date} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Contact way </Label>
                        <Select required
                          name="contact_way"
                          options={contact_ways}
                          value={formData.contact_way}
                          onChange={handleSelectChange}
                        />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Product </Label>
                        <Select required
                          name="product"
                          options={products}
                          value={formData.product}
                          onChange={handleSelectChange}
                        />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Size </Label>
                        <Input  required id="exampleAmount" type='number' step={any} name="size" value={formData.size} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Coming form </Label>
                        <Select required
                          name="coming_from"
                          options={coming_froms}
                          value={formData.coming_from}
                          onChange={handleSelectChange}
                        />
                      </FormGroup>
                </div>
                <div className='col-md-12'>
                      <FormGroup>
                        <Label for="exampleAmount">Rented Befor </Label>
                        <Select required
                          name="rented_before"
                          options={rented_before_options}
                          value={formData.rented_before}
                          onChange={handleSelectChange}
                        />
                      </FormGroup>
                </div>
           </Row>
            
           
            
            
            <Button>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </element>
  );
};

export default Edit;
