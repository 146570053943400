import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input ,Row ,  Alert} from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const AddExpenses = ({ addItem }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () => setModal(!modal);

  const initialFormData = { name: '' };
  const [branches, setBranches] = useState([]);
  const [sales_men, setSalesMen] = useState([]);
  const [formData, setFormData] = useState({
    name:null,
    email:null,
    type:null,
    password:null,
    confirm_password:null
  });
  

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    
  };
  const user = JSON.parse(localStorage.getItem('authUser'));
  let config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = JSON.parse(localStorage.getItem('authUser'));
      let config = {
        headers: {
          'Authorization': 'Bearer ' + user.token,
        },
      };
      setShowLoader(true);
      axios.post(base_url + 'users/add', {...formData}, config).then((response) => {
        const res = response.data;
        if (res.state === 1) {
          setMessage(res.msg);
          const new_item = { 
            id: res.data.id,
            email: res.data.email,
            name: formData.name,
            type: formData.type,
            type_name: res.data.type_name,
          };
          addItem(new_item);
          setShowLoader(false);
          // setFormData(initialFormData);
          setMessage('Added Successfully')
          setTimeout(() => {
            setMessage('');
            toggle();
          }, 1000);
          
        } else {
          setShowLoader(false);
          setMessage(res.msg);
        }
      });
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };

  
  return (
    <element>
      <Button className="pullRight" color="primary" onClick={toggle}>
        +
      </Button>
      <Modal isOpen={modal} toggle={toggle} style={{ minWidth: '900px',background:'#000' }}>
        <ModalHeader toggle={toggle}>Add User</ModalHeader>
        <ModalBody>
            {message && <Alert color="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
           <Row>
               
           <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Name</Label>
                        <Input id="exampleAmount" name="name" value={formData.name} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
              
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Email </Label>
                        <Input id="exampleAmount" type='email' name="email" value={formData.email} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-12'>
                      <FormGroup>
                        <Label for="exampleBranch">Type</Label>
                        <select name="type" value={formData.type} onChange={handleChange} 
                          class="form-control form-control" >
                          <option key="0" value="0">Admin</option>
                          <option key="1" value="1">Employee</option>
                          <option key="2" value="2"> Renewal department</option>
                        </select>
                      </FormGroup>
                </div>
               
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Password </Label>
                        <Input id="exampleAmount" type='password' name="password"
                         value={formData.password} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
                <div className='col-md-6'>
                      <FormGroup>
                        <Label for="exampleAmount">Confirm Password </Label>
                        <Input id="exampleAmount" type='password' 
                        name="confirm_password" value={formData.confirm_password} onChange={handleChange} placeholder="" />
                      </FormGroup>
                </div>
           </Row>
            
           
            
            
            <Button>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </element>
  );
};

export default AddExpenses;
