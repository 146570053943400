import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import Miniwidget from "./Miniwidget";
import MonthlyEarnings from "./montly-earnings";
import EmailSent from "./email-sent";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";
import { setBreadcrumbItems } from "../../store/actions";
import axios from "axios";
import { base_url } from "Constants";

const Dashboard = (props) => {
  document.title = "Dashboard | SuperOffice"; 

  const [reports, setReports] = useState([
    { title: "Clients",link:'/clients', iconClass: "account-multiple-plus-outline", total: "0", average: "-29%", badgecolor: "danger" },
    { title: "Leads",link:'/leads', iconClass: "alpha-l-box-outline", total: "0", average: "+11%", badgecolor: "success-box" },
    { title: "Renewals",link:'/renewals', iconClass: "clipboard-outline", total: "0", average: "0%", badgecolor: "danger-light-box" },
    { title: "Week",link:'/renewals?days=15', iconClass: "clipboard-outline", total: "0", average: "+89%", badgecolor: "danger-box" },
  ]); 
  const [account, setAccount] = useState({
    payments: 0,
    expenses: 0,
    rents: 0,
  });
  const [items, setItems] = useState(null);
  const [clients, setClients] = useState(null);
  const [leads_data, setLeadsData] = useState(null);

  useEffect(() => {
    const breadcrumbItems = [
      { title: "SuperOffice", link: "#" },
      { title: "Dashboard", link: "#" }
    ];
    props.setBreadcrumbItems('Dashboard', breadcrumbItems);

    const user = JSON.parse(localStorage.getItem('authUser'));
    const config = {
      headers: { 'Authorization': 'Bearer ' + user.token }
    };

    axios.get(`${base_url}statistics`, config).then(response => {
      const res = response.data;
      setReports(prevReports =>
        prevReports.map(report => {
          if (report.title === "Leads") {
            return { ...report, total: res.leads };
          } else if (report.title === "Clients") {
            return { ...report, total: res.clients_count };
          } else if (report.title === "Renewals") {
            return { ...report, total: res.rents_count };
          } else if (report.title === 'Week') {
            return { ...report, total: res.rents_week };
          } else {
            return report;
          }
        })
      );
      setAccount(res.account);
      setItems(res.chart);
      setClients(res.clients)
      setLeadsData(res.lead_data);
    });

  }, []);

  return (
    <React.Fragment>
      <Miniwidget reports={reports} />
      <Row>
        <Col xl="6">
          <MonthlyEarnings account={account} />
        </Col>
        <Col xl="6">
          {
            items &&(<EmailSent items={items} />)
          }
          
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          {
            clients &&(
              <LatestTransactions clients={clients} />
            )
          }
        </Col>
        <Col xl="6">
          {
            leads_data &&(
              <LatestOrders  leads_data={leads_data}/>
            )
          }
          
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(Dashboard);
