import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const EditClient = ({ updateItem , item }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  } 

  const [formData, setFormData] = useState({
    name: item.name,
    phone: item.phone,
    email: item.email,
    date: item.date,
    channel: item.channel,
    location: item.location,
    notes: item.notes,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  const config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowLoader(true);
      const response = await axios.post(`${base_url}clients/edit/${item.id}`,formData, config);
      const res = response.data;
      setMessage(res.msg);
      setShowLoader(false);
      if (res.state === 1) {
        updateItem({
          ...item,
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          location: formData.location,
          date: formData.date,
          channel: formData.channel,
          notes: formData.notes,
         
        });
        toggle();
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      setShowLoader(false);
    }
  };

  // useEffect(() => {
    
  // }, []);

  return (
    <span>
      <Button style={{ marginRight: '10px' }} className="pullRight" color="primary" onClick={toggle}>
        Edit
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{ item.name }</ModalHeader>
        <ModalBody>
          {message && <h3>{message}</h3>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
            <FormGroup>
              <Label for="exampleAmount">Name</Label>
              <Input id="exampleAmount" name="name" value={formData.name} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">Phone</Label>
              <Input id="exampleAmount" name="phone" value={formData.phone} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">Email</Label>
              <Input id="exampleAmount" type='email' name="email" value={formData.email} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">channel</Label>
              <Input id="exampleAmount" name="channel" value={formData.channel} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">date</Label>
              <Input id="exampleAmount" type="date" name="date" value={formData.date} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">location</Label>
              <Input id="exampleAmount" name="location" value={formData.location} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">Notes</Label>
              <Input id="exampleAmount" type="textarea" rows="6" name="notes" value={formData.notes} onChange={handleChange} placeholder="" />
            </FormGroup>
            <Button>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </span>
  );
};

export default EditClient;
