import React from "react";
import { Navigate } from "react-router-dom";

const Adminmiddleware = (props) => {
  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }else{
    const user = JSON.parse(localStorage.getItem('authUser'));
    if (user.type == 1) {
      return (
        <Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} />
      );
    }
  }
  return (<React.Fragment>
   {props.children}
  </React.Fragment>);
};

export default Adminmiddleware;
