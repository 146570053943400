import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const AddExpenses = ({ addItem }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () => setModal(!modal);

  const initialFormData = { 
    name: null,
    phone: null,
    email: null,
    date: null,
    location: null,
    channel: null,
    notes:null 
  };
  const [formData, setFormData] = useState({
    name: null,
    phone: null,
    email: null,
    date: null,
    location: null,
    channel: null,
    notes:null,
  });
 

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
      
    }
  };

 
  const user = JSON.parse(localStorage.getItem('authUser'));
  let config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = JSON.parse(localStorage.getItem('authUser'));
      let config = {
        headers: {
          'Authorization': 'Bearer ' + user.token,
        },
      };
      setShowLoader(true);
      axios.post(base_url + 'clients/add', formData, config).then((response) => {
        const res = response.data;
        if (res.state === 1) {
          setMessage(res.msg);
          const new_item = { id: res.data.id,name: res.data.name,phone: res.data.phone,
            email: res.data.email,notes: res.data.notes,
            date:res.data.date,location:res.data.location,channel:res.data.channel
          };
          addItem(new_item);
          setShowLoader(false);
          // setFormData(initialFormData);
          toggle();
        } else {
          setShowLoader(false);
          setMessage(res.msg);
        }
      });
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };

 

  return (
    <span>
      <Button className="pullRight" color="primary" onClick={toggle}>
        +
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Client</ModalHeader>
        <ModalBody>
          {message && <h3>{message}</h3>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
            
            <FormGroup>
              <Label for="exampleAmount">Name</Label>
              <Input id="exampleAmount" name="name" value={formData.name} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">Phone</Label>
              <Input id="exampleAmount" name="phone" value={formData.phone} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">Email</Label>
              <Input id="exampleAmount" type='email' name="email" value={formData.email} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">channel</Label>
              <Input id="exampleAmount" name="channel" value={formData.channel} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">date</Label>
              <Input id="exampleAmount" type="date" name="date" value={formData.date} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">location</Label>
              <Input id="exampleAmount" name="location" value={formData.location} onChange={handleChange} placeholder="" />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAmount">Notes</Label>
              <Input id="exampleAmount" type="textarea" rows="6" name="notes" value={formData.notes} onChange={handleChange} placeholder="" />
            </FormGroup>
            
           
            <Button>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </span>
  );
};

export default AddExpenses;
