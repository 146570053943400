import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import axios from "axios";
import { base_url } from "../../Constants";
import { Button, Alert, Card, CardBody, Pagination, PaginationItem, PaginationLink, Input } from 'reactstrap';
import MyModal from "./MyModal";
import { Link, useLocation } from "react-router-dom";

const Renewal = (props) => {
  const breadcrumbItems = [
    { title: "SuperOffice", link: "/" },
    { title: "Dashboard", link: "/" },
    { title: "Rents", link: "/rents" },
    { title: "Renewals", link: "/renewals" }
  ];

  const [formData, setFormData] = useState({
    start_date: '',
    end_date: '',
    type: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [flashMessage, setFlashMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeDays, setActiveDays] = useState(30);

  const toggleModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(!isModalOpen);
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  const config = {
    headers: {
      'Authorization': 'Bearer ' + user.token
    }
  };

  const handleDelete = () => {
    setIsModalOpen(false);
    setShowLoader(true);
    axios.get(`${base_url}rents/delete/${selectedItem.id}`, config).then(response => {
      if (response.data.state === 1) {
        setItems(prevItems => prevItems.filter(item => item.id !== selectedItem.id));
        setFlashMessage("Property deleted successfully!");
        setShowFlashMessage(true);
        setShowLoader(false);
        setTimeout(() => setShowFlashMessage(false), 3000);
      }
    });
  };

  const addItem = (newItem) => {
    setItems([...items, newItem]);
  };

  const searchSubmit = async (e, days) => {
    e.preventDefault();
    setShowLoader(true);
    axios.get(`${base_url}renewals?days=${days}`, config)
      .then(response => {
        const res = response.data;
        setItems(res.data);
        setTotalPages(res.meta.last_page);
        setShowLoader(false);
      });
    setActiveDays(days);
  };

  const location = useLocation();

  useEffect(() => {
    document.title = "All Rents"; // Set document title in useEffect
  }, []);

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard', breadcrumbItems);
    setShowLoader(true);

    const queryParams = new URLSearchParams(location.search);
    const daysParam = queryParams.get('days');
    if (daysParam) {
      setActiveDays(Number(daysParam));
    }

    axios.get(`${base_url}renewals?page=${currentPage}&days=${activeDays}`, config).then(response => {
      const res = response.data;
      setItems(res.data);
      setTotalPages(res.meta.last_page);
      setShowLoader(false);
    });
  }, [currentPage, activeDays]); // Added activeDays to dependencies

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Link className="btn btn-md btn-primary pull-right" to="/rents/add">Add</Link>

          <div className="table-holder" style={{ position: 'relative' }}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}

            {showFlashMessage && (
              <Alert color="success">
                {flashMessage}
              </Alert>
            )}
            <ul className="switch_ul">
              <li>
                <button onClick={(e) => searchSubmit(e, 30)} className={`switch_btn btn-primary ${activeDays === 30 ? 'active' : ''}`}>One Month</button>
              </li>
              <li>
                <button onClick={(e) => searchSubmit(e, 15)} className={`switch_btn btn-primary ${activeDays === 15 ? 'active' : ''}`}>One Week</button>
              </li>
            </ul>
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Office</th>
                  <th scope="col">Company</th>
                  <th scope="col">Client</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Renewal Date</th>
                  <th scope="col">Options</th>
                </tr>
              </thead>
              <tbody>
                {items.map(item => (
                  <tr key={item.id} className={item.state}>
                    <td>{item.no}</td>
                    <td>{item.branch + ' ' + item.properity}</td>
                    <td>{item.company_name}</td>
                    <td>{item.client_name}</td>
                    <td>{item.amount}</td>
                    <td>{item.duration}</td>
                    <td>{item.renewal_date}</td>
                    <td>
                      <Link to={`/rents/edit/${item.id}`}
                        name={item.name}
                        id={item.id}
                        style={{ marginRight: '5px', color: '#fff !important' }}
                        className="text-muted btn btn-md btn-primary">
                        Edit
                      </Link>
                      <Button color="danger" onClick={() => toggleModal(item)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav aria-label="Page navigation example">
              <Pagination>
                <PaginationItem disabled={currentPage <= 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageChange(currentPage - 1)}
                  />
                </PaginationItem>
                {[...Array(totalPages)].map((_, index) => (
                  <PaginationItem active={index + 1 === currentPage} key={index}>
                    <PaginationLink onClick={() => handlePageChange(index + 1)}>
                      {index + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage >= totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageChange(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </nav>
          </div>

          <MyModal
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            title="Delete Property"
            onDelete={handleDelete}
          >
            <p>Are you sure you want to delete this property?</p>
          </MyModal>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default connect(null, { setBreadcrumbItems })(Renewal);
