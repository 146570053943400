import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Form, Label, Input } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { base_url } from "../../Constants";

const EditExpenses = ({ updateItem , item }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const toggle = () =>{
    setModal(!modal);
    axios.get(`${base_url}expenses/needs?branch_id=${item.branch_id}`, config).then((response) => {
      const res = response.data;
      setBranches(res.branches.map((b) => ({ value: b.id, label: b.name })));
      setExpensesTypes(res.types.map((t) => ({ value: t.id, label: t.name })));
      setProperities(res.properities.map((p) => ({ value: p.id, label: p.name })));
    });
  } 

  const [branches, setBranches] = useState([]);
  const [properities, setProperities] = useState([]);
  const [expensesTypes, setExpensesTypes] = useState([]);
  const [formData, setFormData] = useState({
    amount: item.amount,
    branch_id: { value: item.branch_id, label: item.branch },
    properity_id: { value: item.properity_id, label: item.properity },
    expenses_type_id: { value: item.expenses_type_id, label: item.expenses_type },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
    if (name === 'branch_id') {
      handleBranchChange(selectedOption);
    }
  };

  const user = JSON.parse(localStorage.getItem('authUser'));
  const config = {
    headers: {
      'Authorization': 'Bearer ' + user.token,
    },
  };

  const handleBranchChange = (selectedOption) => {
    axios.get(`${base_url}expenses/needs?branch_id=${selectedOption.value}`, config).then((response) => {
      const res = response.data;
      const properitiesData = res.properities.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setProperities(properitiesData);
      if (properitiesData.length > 0) {
        setFormData((prevData) => ({
          ...prevData,
          properity_id: properitiesData.find(p => p.value === item.properity_id) || null,
        }));
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowLoader(true);
      const response = await axios.post(`${base_url}expenses/edit/${item.id}`, {
        ...formData,
        branch_id: formData.branch_id.value,
        properity_id: formData.properity_id?formData.properity_id.value:null,
        expenses_type_id: formData.expenses_type_id.value,
      }, config);
      const res = response.data;
      setMessage(res.msg);
      setShowLoader(false);
      if (res.state === 1) {
        updateItem({
          ...item,
          amount: formData.amount,
          branch_id: formData.branch_id.value,
          branch: formData.branch_id.label,
          properity_id: formData.properity_id?formData.properity_id.value:null,
          properity: formData.properity_id?formData.properity_id.label:null,
          expenses_type_id: formData.expenses_type_id.value,
          expenses_type: formData.expenses_type_id.label,
        });
        toggle();
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      setShowLoader(false);
    }
  };

  // useEffect(() => {
    
  // }, []);

  return (
    <span>
      <Button style={{ marginRight: '10px' }} className="pullRight" color="primary" onClick={toggle}>
        Edit
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit Expenses</ModalHeader>
        <ModalBody>
          {message && <h3>{message}</h3>}
          <Form onSubmit={handleSubmit}>
            {showLoader && (
              <div id="preloader" style={{ position: 'absolute' }}>
                <div id="status">
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
            )}
            <FormGroup>
              <Label for="expensesType">Expenses type</Label>
              <Select
                name="expenses_type_id"
                options={expensesTypes}
                value={formData.expenses_type_id}
                onChange={handleSelectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="amount">Amount</Label>
              <Input id="amount" name="amount" value={formData.amount} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="branch">Branch</Label>
              <Select
                name="branch_id"
                options={branches}
                value={formData.branch_id}
                onChange={handleSelectChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="properity">Properity</Label>
              <Select
                name="properity_id"
                options={properities}
                value={formData.properity_id}
                onChange={handleSelectChange}
              />
            </FormGroup>
            <Button>Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
    </span>
  );
};

export default EditExpenses;
