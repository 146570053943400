import React from 'react';
import './Popup.css'; // Create this file for styling

const Popup = ({ item, isVisible, onClose }) => {
    
  if (!isVisible) return null;
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={e => e.stopPropagation()}>
        <span className="close-btn" onClick={onClose}>&times;</span>
        <div dangerouslySetInnerHTML={{ __html: item.view }} />
      </div>
    </div>
  );
};

export default Popup;
